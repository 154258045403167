import React, { Suspense, useState, useEffect } from "react";
import {
  RouterProvider,
  Routes,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import Dashboard from "../../containers/Dashboard/Dashboard";
import Home from "../../containers/Home/home";
import Menu from "../../components/Menu/Desktop/Menu";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ScrollButton from "../../common/scrollTop/scrolButton";
import AboutUs from "../../containers/AboutUs/aboutUs";
import WhatItMeansToBeMbrdi from "../../containers/WhatItMeansToBeMbrdi/WhatItMeansToBeMbrdi";
import PressRelease from "../../containers/PressRelease/pressRelease";
import Csr from "../../containers/CSR/Csr";
import Benefits from "../../containers/Benefits/Benefits";
import Privacy from "../../containers/Privacy/privacy";
import "./layout.scss";
import NewsRoom from "../NewsRoom/newsRoom";
import InNews from "../../containers/InNews/inNews";
import EHS from "../../containers/EHS/ehs";
import HiringOppurtunities from "../../containers/HiringOpportunities/hiringOpportunities";
import PeoplePractise from "../../containers/PeoplePractice/peoplePractise";
import PrideImpact from "../../containers/PrideAndImpact/prideImpact";
import Innovations from "../../containers/Innovations/innovations";
import ElectricRoutes from "../../containers/ElectricRoutes/electricRoutes";
import ContactUs from "../../containers/ContactUs/ContactUs";
import whatWeDo from "../../containers/WhatWeDo/whatWeDo";
import Sustainability from "../../containers/Sustainability/sustainability";
import SustainabilityGarage from "../../containers/SustainabiltyGarage/SustainabilityGarage";
import EventAgenda from "../../containers/EventAgenda/eventAgenda";
import Support from "../../containers/Support/support";
import JoinStar from "../../containers/JoinStar/joinStar";
import MbrdiExperience from "../../containers/MbrdiExperience/mbrdiExperience";
import PwdsMbrdi from "../../containers/PwdsMbrdi/pwdsMbrdi";
import axios from "axios";
import CookieConsent, { Cookies } from "react-cookie-consent";
import devtools from "../../../node_modules/devtools-detect/index.js";
import { get } from "jquery";
import { JwtInterceptor } from "../../common/_interceptors/JwtInterceptor";
import { TokenService } from "../../services/TokenService";

const iconPath = process.env.PUBLIC_URL + "/assets/images/home/";
let isMobile = false;
export default function Layout(props) {
  const location = useLocation();

  const [showHeader, setShowHeader] = useState(true);
  const [sasToken, setSasToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth); // check width size of the window

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    isMobile = window.innerWidth < 700 ? true : false;
  };

  // call your useEffect
  var i = 1;
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    i++;
    let token = localStorage.getItem("access_token");

    getSasTokenCall(token);
    //getToken();
  }, []);

  const getToken = async () => {
    // JwtInterceptor();

    // var getToken = await TokenService.getRefreshToken();
    await TokenService.getToken().then(
      (response) => {
        localStorage.setItem("access_token", response.data.access_token);
        getSasTokenCall(response.data.access_token);
        setIsLoading(true);
      },
      (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          console.log(error.response.status);
        }
        setIsLoading(true);
      }
    );
  };

  const getSasTokenCall = async (token) => {
    // JwtInterceptor();

    // var getToken = await TokenService.getRefreshToken();
    await TokenService.getSaasToken(token).then(
      (response) => {
        if (response.status == 201) {
          console.log(response.status);
          console.log("---------+------------");
          getToken();
        } else {
          setSasToken(response.data);
          localStorage.setItem("sas_token", response.data);
          // setSasToken(response.data)
          setIsLoading(true);
        }
      },
      (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status == 403) {
            getToken();
          }
        }
        setIsLoading(true);
      }
    );
  };
  const getNewToken = async () => {
    // JwtInterceptor();

    // var getToken = await TokenService.getRefreshToken();
    await TokenService.getToken().then(
      (response) => {
        setSasToken(response.data);
        localStorage.setItem("sas_token", response.data);
        // setSasToken(response.data)
        setIsLoading(true);
      },
      (error) => {
        setIsLoading(true);
      }
    );
  };
  useEffect(() => {
    /*
    document.addEventListener('contextmenu', function(e) {
      e.preventDefault();
    });
    if (devtools.isOpen) {


      setInterval(() => {
  
          var $all = document.querySelectorAll("*");
  
          for (var each of $all) {
              each.classList.add(`asdjaljsdliasud8ausdijaisdluasdjasildahjdsk${Math.random()}`);
          }
          
  
      }, 5);
  }
  
    document.onkeydown = function(e) { 
      if(e.keyCode == 123) { 
      return false; 
      } 
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){ 
      return false; 
      } 
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){ 
      return false; 
      } 
      if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){ 
      return false; 
      } 
      }
      */
  });

  useEffect(() => {
    //  JwtInterceptor();
    // getSasTokenCall()
    let token = localStorage.getItem("access_token");
    //  alert(location.pathname);
    console.log(location);

    if (i == 1) {
      getSasTokenCall(token);
      // getToken();
    }

    window.scrollTo(0, 0);
  }, [location.pathname]);
  const cookieConsent = () => {
    console.log("window..", window.innerWidth, isMobile);
    let styleResponsive = !isMobile
      ? {
          background: "#fff",
          display: "block",
          top: "40%",
          position: "fixed",
          width: window.innerWidth / 2.5,
          left: window.innerWidth / 3,
        }
      : {
          background: "#fff",
          display: "block",
          top: "40%",
          position: "fixed",
          width: window.innerWidth,
        };
    return (
      <CookieConsent
        contentClasses="cookie-consent"
        location={"middle"}
        buttonText="Agree"
        declineButtonText="Close"
        // containerClasses="alert alert-warning col-lg-12"
        enableDeclineButton
        flipButtons
        style={styleResponsive}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        <h1 className="cookie-title">We use cookies</h1>
        <p className="consent-details">
          We want to make our website more user-friendly and continuously
          improve it. If you continue to use the website, you agree to the use
          of cookies.
        </p>
        <a className="consent-link" target="new" href="#/privacy">
          For more information, please refer to our Privacy statement >
        </a>
      </CookieConsent>
    );
  };

  return (
    <div>
      {showHeader && <Header></Header>}
      {cookieConsent()}
      {isLoading && (
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/press-release" component={PressRelease} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/mbrdi-experience" component={MbrdiExperience} />
          <Route exact path="/what-we-do" component={whatWeDo} />
          <Route exact path="/in-the-news" component={InNews} />
          <Route exact path="/ehs" component={EHS} />
          <Route
            exact
            path="/hiring-opportunities"
            component={HiringOppurtunities}
          />
          <Route exact path="/csr" component={Csr} />
          <Route
            exact
            path="/what-it-means-to-be-mbrdi"
            component={WhatItMeansToBeMbrdi}
          />
          <Route exact path="/benefits" component={Benefits} />
          <Route exact path="/people-practise" component={PeoplePractise} />
          <Route exact path="/pride-impact" component={PrideImpact} />
          <Route exact path="/join-star" component={JoinStar} />
          <Route exact path="/pwds-mbrdi" component={PwdsMbrdi} />
          <Route exact path="/innovations" render={() => <Innovations />} />
          <Route exact path="/electric-routes" component={ElectricRoutes} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route
            exact
            path="/sustainability-23"
            render={() => <Sustainability />}
          />
          <Route
            exact
            path="/sustainability-24"
            render={() => <Sustainability />}
          />
          <Route
            exact
            path="/sustainability-garage"
            render={() => <SustainabilityGarage />}
          />
          <Route exact path="/event-agenda-23" component={EventAgenda} />
          <Route exact path="/support-23" component={Support} />
          <Route exact path="/event-agenda-24" component={EventAgenda} />
          <Route exact path="/support-24" component={Support} />
          <Route path="/" component={Home} />
        </Switch>
      )}
      <Footer></Footer>
      <ScrollButton />
    </div>
  );
}
