import React, { useState, useEffect } from "react";
import "./ehs.scss";
import "intersection-observer";
import data from './ehs.json'
import { Card } from "react-bootstrap";
import Button from "../../common/Buttons/button";
import ContactFooter from "../../common/ContactFooter/contactFooter";
import constants from "../../constants/constants";
import TwoColumnLayout from "../../common/TwoColumnLayout/TwoColumnLayout";
import iconPathURL from "../../common/iconPath";
const iconPath = process.env.PUBLIC_URL + "/assets/images/";

  const CardView = (props) => {
    const body = () => {
      return (
        <div>
          <h2 className="title-content">{props.item.p1}</h2>
          <div>
          <p >{props.item.details}</p>
          </div>
        </div>
      );
    };
    return (
      <div className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
        <TwoColumnLayout
          style="col-xs-12 col-sm-8 col-md- col-lg-11 second-column"
          imgStyle="col-xs-12 col-sm-4 col-md-2 col-lg-1 first-column"
          imgSrc={iconPathURL(`ehs/${props.item.image}`)}
          body={body()}
        ></TwoColumnLayout>
      </div>
    );
  };

  const CardFooterView = (props)=>{
    return(
        <Card className="box row box-shadow ehs-card">
        
        <Card.Body >
            <div className="box-contents">
            <p className="ehs-footer">{props.item.p1}</p>
          <p className="ehs-footer">{props.item.p2}</p>
          <p className="ehs-footer">{props.item.p3}</p>
          <div>
          <Button   type="pdf" doc="factsheet_1.pdf" label="Sustainability Factsheet 2021"/>
          <Button  style="know-more-button-content" type="pdf" doc="factsheet_2.pdf" label="Click here to read Sustainability Factsheet 2020"/>
          </div>
          </div>
        </Card.Body>
      </Card>
    )
  }

export default function EHS(props) {
    const hairLine =()=>{
        return(
            <hr
            style={{
              color: '#d3d3d3',
              margin:20,
              height: '0px',
            }}
          />
        )
      }
  const ehsData = data.data;

    return (
        <div className="ehs-container" >
          
            <section className="layout"   style={{ backgroundImage: `url(${iconPathURL(`home/h4.png`)})` , backgroundRepeat: "round"}}>
                <div >
                <div className="ehs-head">
                  <div>

                  
                  <div className="ehs" >
                  <h1 className="ehs-text">Environment, Health and Safety Policy</h1>
                  <div className="ehs-details">
                  <p className="ehs-p1">{ehsData.intro.p1}</p>
                        <p className="ehs-p2">{ehsData.intro.p2}</p>
                        </div>
                        </div>
                </div>  
        </div> 
        <div className="ehs-content">
           {ehsData.list.map((item,index)=>{return(
                <CardView
                item={item}
                index={index}
                /> 
            )})}   
       
       <div className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8 ehs-section">
       <em className="ehs-note">{constants.ehsText}</em>
       </div>
        
        <div className="footer-contents">
        <div className="ehs-line">
        {hairLine()}
        </div>
        <h2 className="footer-title title-content">{constants.ehsFooter}</h2>
       
        <CardFooterView
                item={ehsData.footer}

                /> 
            
        </div>
       <ContactFooter
       html={()=>{
        return(
        <span>For any queries on EHS, please write to<a className="email-link" href="mailto: mbrdi_ehs@mercedes-benz.com">{" mbrdi_ehs@mercedes-benz.com"} </a></span>
      )}}
       screen="ehs"
       label="For any queries on EHS, please write to "
       />
       </div> 
       </div>
       </section>
    </div>
    )
  }